/*====================================================================
	БИБЛИОТЕКИ
 ====================================================================*/



/*===================================================================
	РАБОЧИЕ ФАЙЛЫ
 ===================================================================*/
 /* Komyza S.
=======================*/

$(window).scroll(function(){
  var scrollToElem = $(window).scrollTop();
  if(scrollToElem > 80){
    $('.header').addClass('header-active');
  } else{
    $('.header').removeClass('header-active');
  }
});

/*плавная прокрутка по клику*/
$('.menu_link').click(function(){
  var scroll_elem = $(this).attr('href');
  $('html, body').animate({
    scrollTop: $(scroll_elem).offset().top - 100
  }, 1000);
});

$(document).ready(function() {
  $('#burger').on('click', function() {
    $('.menu').toggleClass('menu-active');
  });
});

$(document).ready(function(){

 if(document.documentElement.clientWidth < 992) {
   $('.menu_link').on('click', function() {
     $('.menu').removeClass('menu-active');
   });
 }

});


/*  слайдер
=====================================================================*/
$(document).ready(function(){

$('.slider').slick({
  lazyLoad: 'ondemand',
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  fade: true
});
var dot = $(".slider-list_item");
$('.slider').on("beforeChange", function(event, slick, currentSlide, nextSlide) {
    dot.removeClass("slider-list_item__active").eq(nextSlide).addClass("slider-list_item__active")
});
dot.on("click", function() {
    var i = dot.index(this);
    $('.slider').slick("slickGoTo", i)
});
var dot1 = $(".dots_item");
$('.slider').on("beforeChange", function(event, slick, currentSlide, nextSlide) {
    dot1.removeClass("dots_item__active").eq(nextSlide).addClass("dots_item__active")
});
dot1.on("click", function() {
    var i = dot1.index(this);
    $('.slider').slick("slickGoTo", i)
});

$(".prev").on("click", function() {
    $('.slider').slick("slickPrev")
});
$(".next").on("click", function() {
    $('.slider').slick("slickNext")
});

});

/*	inputmask
=====================================================================*/
$(document).ready(function(){
  $(":input").inputmask();
  $(".mask_phone").inputmask({
    mask: "+7(999) 999-99-99",
    clearIncomplete: true //проверка на заполненность
  });
  $('.email').inputmask({
    mask: "*{1,20}[.*{1,20}]@*{1,20}.*{2,4}",
    greedy: false,
    clearIncomplete: true,
    onBeforePaste: function (pastedValue, opts) {
      pastedValue = pastedValue.toLowerCase();
      return pastedValue.replace("mailto:", "");
    },
    definitions: {
      '*': {
        validator: "[0-9A-Za-z-а-я-_]",
        casing: "lower"
      }
    }
  }
  );
});

/*  https://fancyapps.com/fancybox/3/docs/
==================================================================*/
$('[data-fancybox="images"]').fancybox({
  buttons : [
    'share',
    'thumbs',
    'close'
  ]
});




































/*	map
=====================================================================*/
    function initMap() {
  var centerLatLng = new google.maps.LatLng(55.720722, 37.774463);
  var mapOptions = {
    center: centerLatLng,
    zoom: 16
  };
  var map = new google.maps.Map(document.getElementById("map"), mapOptions);
  // contentString - это переменная в которой хранится содержимое информационного окна.
  // Может содержать, как HTML-код, так и обычный текст.
  // Если используем HTML, то в этом случае у нас есть возможность стилизовать окно с помощью CSS.
  var contentString = '<div class="infowindow">' +
                          '<h3>Г. Москва, Рязанский проспект, д. 24.корпус 2., 10 этаж</h3>' +
                      '</div>';
  // Создаем объект информационного окна и помещаем его в переменную infoWindow
  var infoWindow = new google.maps.InfoWindow({
    content: contentString
  });
  var marker = new google.maps.Marker({
    position: centerLatLng,
    map: map,
    title: "г. Москва, Рязанский проспект, д. 24"
  });
  // Отслеживаем клик по нашему маркеру
  google.maps.event.addListener(marker, "click", function() {
    // infoWindow.open - показывает информационное окно.
    // Параметр map - это переменная содержащие объект карты (объявлена на 8 строке)
    // Параметр marker - это переменная содержащие объект маркера (объявлена на 23 строке)
    infoWindow.open(map, marker);
  });
  // Отслеживаем клик в любом месте карты
  google.maps.event.addListener(map, "click", function() {
    // infoWindow.close - закрываем информационное окно.
    infoWindow.close();
  });
}
google.maps.event.addDomListener(window, "load", initMap);